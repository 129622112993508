export const types = [
  { label: 'Banner', value: 'banner' },
  { label: 'Popup', value: 'popup' },
]

export const DEPOSIT = 'deposit'
export const WITHDRAW = 'withdrawal'

export const typesCashiers = [
  { label: 'Deposito', value: DEPOSIT },
  { label: 'Retiro', value: WITHDRAW },
]

export const BASE_S3 = 'https://s3.sa-east-1.amazonaws.com/estelarbet.new'

export const STATUS_PUBLISHED = 'Publicado'
export const STATUS_NOT_PUBLISHED = 'No publicado'

export default {
  LINK_PROMOTION_SELECTOR_ID: 1,
}

export const devices = [
  { label: 'Todos', value: 'all' },
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
]

export const UNLIMITED = 'Sin límite'

export const VIP = 'vip'
export const GENERAL = 'general'
export const ACTIVE_EXCLUSIVE = 'active_exclusive'
export const INACTIVE_EXCLUSIVE = 'inactive_exclusive'

export const VIP_LIMITS_IDS = [3, 4]
